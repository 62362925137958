<template>
    <div class="page">
        <full-page ref="fullpage" :options="options">
            <div class="section row works">
                <div class="col-md-3 works-left visible-md visible-lg hidden-xs hidden-sm">
                <SidebarWorks @setSubLink="setSubLink" @setLink="setLink" :activeLink="'strategy'" :activeSublink="activeStrategy" :translate="translate" ></SidebarWorks>
                </div>
                <div class="col-md-9 works-right fade-in">
                    <div class="strategy-content">
                            <NavigationWorks class="hidden-md hidden-lg visible-xs visible-sm" :enableNav="true"  @setSubLink="setSubLink" @setLink="setLink" :activeLink="activeStrategy" :activeSublink="activeStrategy" :arrArray="[0,1]" />
                            <h1 class="fade-in"> {{ translate[language].title }}</h1>
                            <div v-show="activeStrategy == 0 " class="strategy-active">
                                <h2 class="fade-in"> {{ translate[language].slide1.title }}</h2>
                                <p class="fade-in" v-html="translate[language].slide1.subtitle"> </p>
                                <img src="/images/image1.png" class="strategy-image fade-in" v-if="language == 'english'">
                                <img src="/images/image1-2.png" class="strategy-image fade-in" v-if="language == 'spanish'">

                            </div>
                            <div v-show="activeStrategy == 1 "  class="strategy-active">
                                <h2 class="fade-in"> {{ translate[language].slide2.title }}</h2>
                                <p class="fade-in" v-html="translate[language].slide2.subtitle"> </p>
                                <img src="/images/image-2.png" class="margin-auto visible-md visible-lg hidden-xs hidden-sm fade-in">
                                <img src="/images/image-3.png" class="margin-auto hidden-md hidden-lg visible-xs visible-sm fade-in" width="80">
                                <p class="fade-in" v-html="translate[language].slide2.content"></p>
                            </div>
                    </div>
                </div>
            </div> 
        </full-page>
    </div>
</template>
<script>
export default {
    data(){
        return {
            activeStrategy: 0,
            language : this.$cookies.get('language') == null ? 'english' : this.$cookies.get('language'),
              options: {
                scrollBar: false,
                responsiveSlides: true,
                easingcss3 : 'cubic-bezier(0.455, 0.310, 0.120, 1.020)',
                licenseKey: '',
                scrollingSpeed : 1000,
                fitToSectionDelay: 2000,
                css3: true,
            },
            translate : {
                spanish: {
                    title : 'ESTRATEGIA',
                    slide1 : {
                        title: 'PORTAFOLIO DIVERSO',
                        subtitle: 'La generación de una cartera diversa de Hogares Residenciales y Residenciales Plus en México',
                        content: '<p>No más del 60% invertido en una sub-categoría </p> <p> Y no más del 40% en una sola propiedad </p>',
                    },
                    slide2 : {
                        title : 'DIVERSIFICACIÓN',
                        subtitle: '<p>No más del 60% invertido en una sub-categoría Y no más del 40% en una sola propiedad </p>',
                        content: '',
                    },
                },
                english: {
                    title : 'STRATEGY',
                    slide1 : {
                        title: 'GENERATE A BROAD PORTFOLIO OF PRODUCTS',
                        subtitle: 'RESIDENTIAL & RESIDENTIAL PLUS IN MEXICO',
                         content: '',
                    },
                    slide2 : {
                        title: 'DIVERSIFICATION',
                        subtitle: '<p>NO MORE THAN 60% INVESTED IN A SUB-CATEGORY AND  NO MORE THAN 40% IN A SINGLE PROPERTY </p>',
                        content: '',
                    },
                    

                },
            }
        }
    },
    methods: {
        setLink( activeStrategy ){
            this.activeStrategy = activeStrategy
        },
        setSubLink( activeStrategy ){
            this.activeStrategy = activeStrategy
        },
        checkLanguage(){
            let self = this 
            setInterval( function(){
                self.language = ( self.$cookies.get('language') == null ? 'english' : self.$cookies.get('language')  )   // return value      
            }, 1000 )
        },
    },
    created(){
        this.checkLanguage()
    }
 
}
</script>

